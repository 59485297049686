import {
	Module,
	VuexModule,
	MutationAction,
	getModule,
} from 'vuex-module-decorators';
import { TabItemType } from '@/classes/header/TabItemType';
import store from '@/store/index';

@Module({
	name: 'modules/headerModule',
	dynamic: true,
	store: store,
})
export default class Header extends VuexModule {
	tabIndex = 0;
	search = '';
	stepperIndex = 0;
	tabItems: TabItemType[] = [];
	stepperItems: string[] = [
		'Regions',
		'Services',
		'Surcharge & Exclusions',
		'Complete',
	];
	title = '';

	@MutationAction({ mutate: ['tabIndex'] })
	public async UpdateTabIndex(index: number) {
		return {
			tabIndex: index,
		};
	}

	@MutationAction({ mutate: ['search'] })
	public async UpdateSearch(search: string) {
		return {
			search: search,
		};
	}

	@MutationAction({ mutate: ['stepperIndex'] })
	public async UpdateStepperIndex(index: number) {
		return {
			stepperIndex: index,
		};
	}

	@MutationAction({ mutate: ['title'] })
	public async UpdateTitle(title: string) {
		return {
			title: title,
		};
	}

	@MutationAction({ mutate: ['tabItems'] })
	public async UpdateTabItems(items: TabItemType[]) {
		return {
			tabItems: items,
		};
	}

	@MutationAction({ mutate: ['stepperItems'] })
	public async UpdateStepperItems(items: string[]) {
		return {
			stepperItems: items,
		};
	}

	// Gets the reference to the zones store
	get GetTabIndex(): number {
		return this.tabIndex;
	}

	get GetTitle(): string {
		return this.title;
	}

	get GetSearch(): string {
		return this.search;
	}

	get GetTabItems(): TabItemType[] {
		return this.tabItems;
	}

	get GetStepperIndex(): number {
		return this.stepperIndex;
	}

	get GetStepperItems(): string[] {
		return this.stepperItems;
	}
}

export const HeaderModule = getModule(Header);
