





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class NoData extends Vue {
	@Prop() noDataText!: string;
	@Prop() noDataImage!: string;
}
