



































import Vue from 'vue';
import { SupplierService } from '@/services/suppliers/supplierService';
import { Supplier } from '@/classes/suppliers/supplier';

// Modules
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { HeaderModule } from '@/store/header/header';

// Components
import LoadingData from '@/components/datadisplay/LoadingData.vue';
import { Component, Watch } from 'vue-property-decorator';
import NoData from '@/components/datadisplay/NoData.vue';

@Component({
	components: { LoadingData, NoData },
})
export default class SelectSupplier extends Vue {
	suppliersService = new SupplierService();
	suppliers: Supplier[] = [];
	headerModule = HeaderModule;
	backupSuppliers: Supplier[] = [];
	isLoading = true;

	@Watch('headerModule.GetSearch')
	onSearchChanged(val: string) {
		if (val && this.backupSuppliers.length !== 0) {
			const filteredSuppliers = this.backupSuppliers.filter(xx =>
				xx.SupplierName.toString()
					.toLowerCase()
					.includes(val.toLowerCase())
			);

			if (filteredSuppliers.length > 0) {
				this.suppliers = filteredSuppliers;
			} else if (filteredSuppliers.length == 0) {
				this.suppliers = [];
			}
		} else {
			this.suppliers = this.backupSuppliers;
		}
	}

	created() {
		this.headerModule.UpdateTitle('Select Supplier');
	}

	async beforeMount() {
		const selectedSupplier = localStorage.getItem('selectedSupplier');
		const exists = SuppliersModule.GetSelectedSupplier;

		// if supplier exists in storage update store
		if (selectedSupplier && !exists.SupplierId) {
			SuppliersModule.SelectSupplier(JSON.parse(selectedSupplier) as Supplier);
			this.$router.replace({
				replace: true,
				path: this.$auth.appState
					? this.$auth.appState.targetUrl.pathname !== '/'
						? this.$auth.appState.targetUrl.pathname
						: '/dashboard'
					: '/dashboard',
			});
			return;
		}

		// this.suppliers = await SuppliersModule.GetSuppliers;
		this.suppliers = await this.suppliersService.GetSuppliers();
		this.backupSuppliers = this.suppliers;
		SuppliersModule.UpdateSuppliers(this.suppliers);

		if (this.suppliers.length === 1) {
			SuppliersModule.SelectSupplier(this.suppliers[0]);
			this.$router.replace({
				replace: true,
				path: this.$auth.appState
					? this.$auth.appState.targetUrl.pathname !== '/'
						? this.$auth.appState.targetUrl.pathname
						: '/dashboard'
					: '/dashboard',
			});
		}
		this.isLoading = false;
		// Previous Location
		// console.log((this.$router as any).history._startLocation);
	}

	SelectSupplier(supplier: Supplier) {
		SuppliersModule.SelectSupplier(supplier);
		this.$router.replace({
			replace: true,
			path: this.$auth.appState
				? this.$auth.appState.targetUrl.pathname !== '/'
					? this.$auth.appState.targetUrl.pathname
					: '/dashboard'
				: '/dashboard',
		});
	}
}
