import axios from 'axios';
import Vue from 'vue';
import { URL } from '@/assets/settings/settings';
import { AuthStore } from '@/store/auth/auth';
import { getInstance } from '../auth/auth';
import { audience } from '../auth//auth.config.json';
import router from '@/router';

const baseUrl = URL;

let toast: any = null;
// let subscribers: any[] = [];
// URL for subcribers of recalling https://gist.github.com/FilipBartos/c2cc4df3dfda49f71360295e4101db2b
let isAlreadyFetchingAccessToken = false;

// let isRenewing = false;

const instance = axios.create({
	baseURL: baseUrl,
});

instance.interceptors.request.use(
	(config: any) => {
		let accessToken = AuthStore.accessToken;
		if ((window as any).Cypress) {
			accessToken = sessionStorage.getItem('access_token') || '';
		}

		config.headers.common.Authorization = 'Bearer ' + accessToken;
		return config;
	},
	(error: any) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response: any) => {
		return response;
	},
	async (error: any) => {
		const { config, response } = error;
		const originalRequest = config;

		if (response && response.status === 401) {
			let accessToken = '';

			if (!isAlreadyFetchingAccessToken) {
				const authService = getInstance();

				isAlreadyFetchingAccessToken = true;

				if ((window as any).Cypress) {
					accessToken = sessionStorage.getItem('access_token') || '';
				} else {
					accessToken = await authService.getTokenSilently({
						audience: audience,
					});
				}

				AuthStore.updateAccessToken(accessToken);

				authService.isAuthenticated = await authService.auth0Client?.isAuthenticated();
				authService.user = await authService.getUser();
				authService.loading = false;

				isAlreadyFetchingAccessToken = false;

				originalRequest.headers.Authorization = 'Bearer ' + accessToken;

				return new Promise(resolve => {
					resolve(axios(originalRequest));
				});
			}
		}

		if (response && response.status === 403) {
			router.replace('/zones');
		}

		console.log(error.response);

		let err = '';
		if (error.response.data) {
			err = await new Response(error.response.data).text();

			console.log(err);

			if (err !== '[object Object]') {
				err = JSON.parse(err).error;
			}
		}

		console.log(err);

		if (!toast && error.response) {
			toast = Vue.toasted.show(
				error.response.data.error ||
					err ||
					error.response.statusText + ' (' + error.response.status + ')',
				{
					theme: 'toasted-primary',
					position: 'bottom-center',
					duration: 20000,
					singleton: true,
					className: 'toastError',
					onComplete: () => {
						toast = null;
					},
				}
			);
		} else if (!toast) {
			toast = Vue.toasted.show(error.message || err, {
				theme: 'toasted-primary',
				position: 'bottom-center',
				duration: 20000,
				singleton: true,
				className: 'toastError',
				onComplete: () => {
					toast = null;
				},
			});
		}

		console.log(error.response);
		return Promise.reject(error);
	}
);

export const axiosBase = instance;
