import {
	Module,
	VuexModule,
	Mutation,
	getModule,
} from 'vuex-module-decorators';
import store from '@/store/index';

@Module({ dynamic: true, store, name: 'auth' })
export default class Auth extends VuexModule {
	accessToken = '';
	isReviewer = false;

	@Mutation
	updateAccessToken(accesToken: string) {
		this.accessToken = accesToken;
	}

	@Mutation
	UpdateReviewer(isReviewer: boolean) {
		this.isReviewer = isReviewer;
	}

	get IsReviewer(): boolean {
		return this.isReviewer;
	}
}

export const AuthStore = getModule(Auth);
