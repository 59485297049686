import {
	Module,
	VuexModule,
	MutationAction,
	getModule,
} from 'vuex-module-decorators';
import { SupplierZoneService } from '@/services/supplierZones/supplierZoneService';
import { ISupplierZone } from '@/classes/supplierZones/ISupplierZone';
import { SupplierZone } from '@/classes/supplierZones/supplierZone';
import store from '@/store/index';
import { IGroupedSupplierZone } from '@/classes/supplierZones/IGroupedSupplierZone';
import { ISupplierZonePostalComponent } from '@/classes/supplierZones/ISupplierZonePostalComponent';
import { ISupplierZonePrice } from '@/classes/supplierZones/ISupplierZonePrice';
import { SupplierZoneServicesService } from '@/services/supplierZones/supplierZoneServicesService';

@Module({
	name: 'modules/ZonesModule',
	dynamic: true,
	store: store,
})
export default class Zones extends VuexModule {
	supplierZonesService = new SupplierZoneService();
	zones: ISupplierZone[] = [];
	zonesPostalComponentChanges: ISupplierZonePostalComponent[] = [];
	selectedZone: ISupplierZone = new SupplierZone();
	selectedZoneGroup: Array<IGroupedSupplierZone> = [];
	groupedZonePrices: Array<{
		Name: string;
		ServicePrices: ISupplierZonePrice[];
	}> = [];

	// Updates the zones collection so that all getters update their display
	@MutationAction({ mutate: ['zones'] })
	public async UpdateZones(zones: ISupplierZone[]) {
		return {
			zones: zones,
		};
	}

	@MutationAction({ mutate: ['zonesPostalComponentChanges'] })
	public async Reset() {
		return {
			zonesPostalComponentChanges: [],
		};
	}

	@MutationAction({ mutate: ['zonesPostalComponentChanges'] })
	public async TrackPostalComponentChanges(
		component: ISupplierZonePostalComponent
	) {
		// Get components from state
		let components = (this.state as any)
			.zonesPostalComponentChanges as ISupplierZonePostalComponent[];

		// Check if component is already in a modified state e.g added or deleted
		const index = components.findIndex(
			xx => xx.PostalComponent?.Id === component.PostalComponent?.Id
		);

		// If the component doesn't exist add it to the list of modified components
		if (index === -1) {
			components.push(component);
		}

		// Filter by passed in component id with update list component id and filter it out of the list
		else {
			components = components.filter(
				xx => xx.PostalComponent?.Id !== component.PostalComponent?.Id
			);
		}

		return {
			zonesPostalComponentChanges: components,
		};
	}

	@MutationAction({ mutate: ['selectedZone'] })
	public async UpdateSelectedZone(zone: ISupplierZone) {
		return {
			selectedZone: zone,
		};
	}

	@MutationAction({ mutate: ['groupedZonePrices'] })
	public async UpdateGroupedZonePrices(
		groupedZonePrices: Array<{
			Name: string;
			ServicePrices: ISupplierZonePrice[];
		}>
	) {
		return {
			groupedZonePrices: groupedZonePrices,
		};
	}

	@MutationAction({ mutate: ['selectedZoneGroup'] })
	public async UpdateSelectedGroupZone(group: Array<IGroupedSupplierZone>) {
		return {
			selectedZoneGroup: group,
		};
	}

	@MutationAction({ mutate: ['zones'] })
	public async AddZone(zone: ISupplierZone) {
		const zones = (this.state as any).zones;
		zones.push(zone);

		return {
			zones: zones,
		};
	}

	// Gets the reference to the zones store
	get GetZones(): ISupplierZone[] {
		return this.zones;
	}

	get GetGroupedZonePrices(): Array<{
		Name: string;
		ServicePrices: ISupplierZonePrice[];
	}> {
		return this.groupedZonePrices;
	}

	get GetSelectedZone(): ISupplierZone {
		return this.selectedZone;
	}

	get GetSelectedZoneGroup(): Array<IGroupedSupplierZone> {
		return this.selectedZoneGroup;
	}

	get GetPostalComponentUpdates(): Array<ISupplierZonePostalComponent> {
		return this.zonesPostalComponentChanges;
	}
}

export const ZonesModule = getModule(Zones);
