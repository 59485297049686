export class User {
	name?: string;
	given_name?: string;
	family_name?: string;
	middle_name?: string;
	nickname?: string;
	preferred_username?: string;
	profile?: string;
	picture?: string;
	website?: string;
	email?: string;
	email_verified?: boolean;
	gender?: string;
	birthdate?: string;
	zoneinfo?: string;
	locale?: string;
	phone_number?: string;
	phone_number_verified?: boolean;
	address?: string;
	updated_at?: string;
	sub?: string;
	[key: string]: any;

	constructor(auth0User: any) {
		if (!auth0User) return;
		for (const key in auth0User) {
			this[key] = auth0User[key];
		}

		this.sub = auth0User.sub as string;
		this.provider = this.sub.split('|')[0];
		this.id = this.sub.split('|')[1];
	}
}
