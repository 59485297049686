import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';
import { Supplier } from '@/classes/suppliers/supplier';

export class SupplierService {
	public GetSuppliers(): Promise<Supplier[]> {
		return new Promise((resolve, reject) => {
			axiosBase.get('/suppliers').then(
				(response: AxiosResponse<Supplier[]>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}
}
