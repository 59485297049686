









































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { ZonesModule } from '@/store/zones/zones';

import { SupplierService } from '@/services/suppliers/supplierService';
import { SupplierZoneService } from '@/services/supplierZones/supplierZoneService';

import { Supplier } from '@/classes/suppliers/supplier';

@Component({
	components: {},
})
export default class SupplierSelect extends Vue {
	menu = false;
	suppliersModule = SuppliersModule;
	supplierService = new SupplierService();
	supplierZonesService = new SupplierZoneService();
	search = '';

	suppliers: Supplier[] = [];
	backupSuppliers: Supplier[] = [];

	@Watch('menu')
	customerOpenMenu(val: boolean) {
		if (val) {
			this.supplierService.GetSuppliers().then(suppliers => {
				this.suppliersModule.UpdateSuppliers(suppliers);
				this.suppliers = suppliers;
				this.backupSuppliers = this.suppliers;
			});
		}
	}

	@Watch('search')
	onSearch(val: string) {
		if (val) {
			const filteredSuppliers = this.backupSuppliers.filter(xx =>
				xx.SupplierName.toString()
					.toLowerCase()
					.includes(val.toLowerCase())
			);

			if (filteredSuppliers.length > 0) {
				this.suppliers = filteredSuppliers;
			}
		} else {
			this.suppliers = this.backupSuppliers;
		}
	}

	UpdateSupplier(supplier: Supplier) {
		this.suppliersModule.SelectSupplier(supplier);
		this.menu = false;

		ZonesModule.UpdateSelectedGroupZone([]);

		if (SuppliersModule.GetSelectedSupplier.SupplierId) {
			this.supplierZonesService
				.GetSupplierZones(SuppliersModule.GetSelectedSupplier.SupplierId)
				.then(zones => {
					ZonesModule?.UpdateZones(zones);
				});
		}
	}

	Logout() {
		this.$router.replace('/');

		this.$auth.logout({
			returnTo: window.location.origin,
		});
	}
}
