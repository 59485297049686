import { Address } from '../base/address/Address';
import { ZoneStatus } from '../supplierZones/zoneStatus';
import { ISupplierContact } from './ISupplierContact';

export class Supplier {
	public SupplierId?: number;
	public ParentId: number | undefined | null;
	public SupplierName = '';
	public ContactName = '';

	public SupplierStatus = 0;
	public AccountNumber = '';
	public Address: Address = new Address();
	public LiabilityValue = '';
	public LiabilityDate: any;
	public CarriersLicence = '';
	public CarriersLicenceExpiry: any;
	public RegistrationNumber = '';
	public IsEIRE = false;
	public PortalStatus: null | ZoneStatus = ZoneStatus.PendingReview;
	public RejectionReason = '';
	public Contacts: ISupplierContact[] = [];

	constructor(supplier = null) {
		if (supplier) {
			this.InitialisePopulatedItem(supplier);
		}
	}

	public InitialisePopulatedItem(supplier: any) {
		this.SupplierId = supplier.SupplierId;
		this.SupplierName = supplier.SupplierName;
		this.SupplierStatus = supplier.SupplierStatus;
	}
}
