import { getInstance } from './auth';
import { NavigationGuard } from 'vue-router';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { AuthStore } from '@/store/auth/auth';
import { Supplier } from '@/classes/suppliers/supplier';

export const authGuard: NavigationGuard = (to, from, next) => {
	const authService = getInstance();
	if ((window as any).Cypress) {
		authService.isAuthenticated = true;
		authService.loading = false;
	}

	const fn = async () => {
		// Unwatch loading
		/* eslint-disable */
    unwatch && unwatch();

		// Checking localstorage for a previous selected supplier
		var selectedSupplier = localStorage.getItem('selectedSupplier');
		var exists = SuppliersModule.GetSelectedSupplier.SupplierId;

		if (selectedSupplier && !exists) {
			SuppliersModule.SelectSupplier(JSON.parse(selectedSupplier) as Supplier);
		}

		if(authService.isAuthenticated && !selectedSupplier && !exists)
		{
			if (to.name == 'SelectSupplier') {
				return next({replace: true});
			} else {
				return next({name: 'SelectSupplier', replace: true});
			}
		}

		if (authService.isAuthenticated){
			return next();
		}

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  const unwatch = authService.$watch('loading', (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
};
