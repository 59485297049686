import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toasted from 'vue-toasted';
import vuetify from './plugins/vuetify';
import { Auth0Plugin } from './services/auth';
import { domain, clientId, audience } from './services//auth/auth.config.json';

Vue.use(Toasted);
Vue.use(Auth0Plugin, {
	domain,
	clientId,
	audience,
	onRedirectCallback: (appState: any) => {
		router.replace(
			appState && appState.targetUrl
				? appState.targetUrl.pathname
				: window.location.pathname
		);
	},
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');
