import {
	Module,
	VuexModule,
	Mutation,
	MutationAction,
	getModule,
} from 'vuex-module-decorators';

import store from '@/store/index';
import { Supplier } from '@/classes/suppliers/supplier';

@Module({
	name: 'modules/Suppliers',
	dynamic: true,
	store: store,
})
export default class Suppliers extends VuexModule {
	suppliers: Supplier[] = [];
	selectedSupplier: Supplier = new Supplier();

	@Mutation
	public UpdateSuppliers(suppliers: Supplier[]) {
		this.suppliers = suppliers;
	}

	@Mutation
	public SelectSupplier(supplier: Supplier) {
		this.selectedSupplier = supplier;
		localStorage.setItem('selectedSupplier', JSON.stringify(supplier));
	}

	get GetSuppliers(): Supplier[] {
		return this.suppliers;
	}

	get GetSelectedSupplier(): Supplier {
		return this.selectedSupplier;
	}
}
export const SuppliersModule = getModule(Suppliers);
