import { authGuard } from '@/services/auth/authGuard';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import SelectSupplier from '../views/SelectSupplier.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '*',
		redirect: {
			name: 'Dashboard',
		},
	},
	{
		path: '/',
		name: 'Login',
		component: Login,
		meta: {
			showAdditionalHeaderBar: false,
		},
	},
	{
		path: '/SelectSupplier',
		name: 'SelectSupplier',
		component: SelectSupplier,
		beforeEnter: authGuard,
		meta: {
			showSearch: true,
			hideNavigation: true,
		},
	},
	{
		path: '/zones',
		name: 'Zones',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views//HomeTabs/Zones/Zones.vue'),
		beforeEnter: authGuard,
		meta: {
			showSupplier: true,
		},
	},
	{
		path: '/zones/:zoneId/region',
		name: 'Region',
		component: () =>
			import('../views//HomeTabs/Zones/Steps/RegionStep/RegionStep.vue'),
		beforeEnter: authGuard,
		meta: {
			showStepper: true,
			stepperIndex: 0,
		},
	},
	{
		path: '/zones/:zoneId/service',
		name: 'Services',
		component: () =>
			import('../views//HomeTabs/Zones/Steps/ServiceStep/ServiceStep.vue'),
		beforeEnter: authGuard,
		meta: {
			showStepper: true,
			stepperIndex: 1,
		},
	},

	{
		path: '/zones/:zoneId/surcharge',
		name: 'Surcharges',
		component: () =>
			import(
				'../views//HomeTabs/Zones/Steps/SurchargeOrExclusions/SurchargeOrExclusions.vue'
			),
		beforeEnter: authGuard,
		meta: {
			showStepper: true,
			stepperIndex: 2,
		},
	},

	{
		path: '/zones/:zoneId/complete',
		name: 'Complete',
		component: () =>
			import('../views//HomeTabs/Zones/Steps/CompleteStep/CompleteStep.vue'),
		beforeEnter: authGuard,
		meta: {
			showStepper: true,
			stepperIndex: 3,
		},
	},
	{
		path: '/documents',
		name: 'Documents',
		component: () => import('../views//HomeTabs/Documents/Documents.vue'),
		beforeEnter: authGuard,
		meta: {
			showSupplier: true,
			showSearch: true,
		},
	},
	{
		path: '/documents/:documentId',
		name: 'Document',
		component: () => import('../views//HomeTabs/Documents/Documents.vue'),

		beforeEnter: authGuard,
		meta: {
			showSupplier: true,
			showSearch: true,
		},
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views//HomeTabs/Dashboard/Dashboard.vue'),
		beforeEnter: authGuard,
		meta: {
			showSupplier: true,
		},
	},
	{
		path: '/vitals',
		name: 'Vitals',
		component: () => import('../views//HomeTabs/Vitals/Vitals.vue'),
		beforeEnter: authGuard,
		meta: {
			showSupplier: true,
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
