import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';
import { ISupplierZone } from '@/classes/supplierZones/ISupplierZone';
import { PostalComponent } from '@/classes/supplierZones/postalComponent';
import { IGroupedSupplierZone } from '@/classes/supplierZones/IGroupedSupplierZone';
import { ISupplierZonePostalComponent } from '@/classes/supplierZones/ISupplierZonePostalComponent';

export class SupplierZoneService {
	public GetSupplierZones(supplierId: number): Promise<ISupplierZone[]> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`/supplierzones?supplierId=${supplierId}`).then(
				(response: AxiosResponse<ISupplierZone[]>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetSupplierZone(
		supplierId: number,
		supplierZoneId: number
	): Promise<ISupplierZone> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(
					`/supplierzones?supplierId=${supplierId}&supplierZoneId=${supplierZoneId}`
				)
				.then(
					(response: AxiosResponse<ISupplierZone>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public CloneSupplierZone(supplierZoneId: number): Promise<ISupplierZone> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`/supplierzones/Clone/${supplierZoneId}`).then(
				(response: AxiosResponse<ISupplierZone>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetSupplierZoneGrouped(
		supplierId: number,
		supplierZoneId: number
	): Promise<Array<IGroupedSupplierZone>> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(
					`/supplierzones/Zone?supplierId=${supplierId}&supplierZoneId=${supplierZoneId}&grouped=true`
				)
				.then(
					(response: AxiosResponse<Array<IGroupedSupplierZone>>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public GetPostalComponentLevel(
		level?: number,
		parentId?: number
	): Promise<PostalComponent> {
		return new Promise((resolve, reject) => {
			let urlBuild = '/supplierzones/level/';

			urlBuild = urlBuild += level ? '?level=' + level : '';
			urlBuild = urlBuild += parentId ? '&parentId=' + parentId : '';

			axiosBase.get(urlBuild).then(
				(response: AxiosResponse<PostalComponent>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetPostalComponents(search: string): Promise<PostalComponent[]> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`/supplierzones/search?search=${search}`).then(
				(response: AxiosResponse<PostalComponent[]>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public CreateSupplierZone(
		supplierZone: ISupplierZone
	): Promise<ISupplierZone> {
		return new Promise((resolve, reject) => {
			axiosBase.post('/supplierzones', supplierZone).then(
				(response: AxiosResponse<ISupplierZone>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public UpdateSupplierZonePostalComponents(
		supplierZonePostalComponents: ISupplierZonePostalComponent[]
	): Promise<void> {
		return new Promise((resolve, reject) => {
			axiosBase
				.post('/supplierzones/component', supplierZonePostalComponents)
				.then(
					(response: AxiosResponse<void>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}
}
