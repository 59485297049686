import { ZoneStatus } from './zoneStatus';
import { ISupplierZone } from './ISupplierZone';
import { ISupplierZonePostalComponent } from './ISupplierZonePostalComponent';

export class SupplierZone implements ISupplierZone {
	public Id = 0;
	public ZoneName = 'New Zone';
	public ParentId = null;
	public SupplierId: number | undefined = 0;
	public SupplierZonePostalComponents: Array<ISupplierZonePostalComponent> = [];
	public RejectionReason?: string;

	public Status: ZoneStatus = ZoneStatus.Draft;

	constructor(supplier = null) {
		if (supplier) {
			this.InitialisePopulatedItem(supplier);
		}
	}

	public InitialisePopulatedItem(supplier: any) {
		this.Id = supplier.Id;
		this.SupplierId = supplier.SupplierId;
		this.ParentId = supplier.ParentId;
		this.ZoneName = supplier.ZoneName;
		this.Status = supplier.SupplierStatus;
		this.RejectionReason = supplier.RejectionReason;
	}
}
