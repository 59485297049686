











































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

// State module imports
import { HeaderModule } from '@/store/header/header';

@Component({
	components: {},
})
export default class HeaderBar extends Vue {
	headerModule = HeaderModule;

	tabIndex = 0;
	@Watch('tabIndex', { deep: true })
	tabIndexChanged(index: number) {
		this.headerModule.UpdateTabIndex(index);
	}

	stepperIndex = 0;
	@Watch('headerModule.GetStepperIndex', { deep: true })
	stepperIndexChanged(index: number) {
		this.stepperIndex = this.headerModule.GetStepperIndex;
		this.headerModule.UpdateStepperIndex(index);
	}

	search = '';
	@Watch('search', { deep: true })
	searchChanged(search: string) {
		this.headerModule.UpdateSearch(search);
	}
}
