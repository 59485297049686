

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class LoadingData extends Vue {
	@Prop() loadingDataText!: string;
}
