var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$route.meta.showAdditionalHeaderBar !== false)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.menu = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.suppliersModule.GetSelectedSupplier.SupplierName)+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account-circle")])],1):_vm._e()]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"35"}},[_c('span',[_vm._v(_vm._s(_vm.suppliersModule.GetSelectedSupplier.SupplierName.substring( 0, 2 )))])]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.suppliersModule.GetSelectedSupplier.SupplierName))])],1)],1)],1)],1),_c('v-divider'),(_vm.suppliersModule.GetSuppliers.length > 1)?_c('div',[_c('h2',{staticClass:"pa-3"},[_vm._v("Change Supplier")]),(_vm.suppliersModule.GetSuppliers.length >= 5)?_c('v-text-field',{staticClass:"rounded-0 px-2",attrs:{"dense":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search Suppliers","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-virtual-scroll',{attrs:{"height":_vm.suppliers.length > 5 ? 300 : (_vm.suppliers.length - 1) * 50,"item-height":50,"items":_vm.suppliers.filter(
							function (xx) { return xx.SupplierId !==
								_vm.suppliersModule.GetSelectedSupplier.SupplierId; }
						)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var item = ref.item;
return [_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:item.SupplierId,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.UpdateSupplier(item)}}},[_c('v-list-item-content',{staticStyle:{"margin-left":"20px","padding":"0"}},[_c('v-list-item-title',[_c('v-avatar',{attrs:{"size":"35"}},[_c('span',[_vm._v(_vm._s(item.SupplierName.substring(0, 2)))])]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(item.SupplierName))])],1)],1)],1),_c('v-divider')]}}],null,false,1148352887)})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.Logout()}}},[_vm._v("Logout")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }