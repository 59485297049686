





































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SupplierService } from '@/services/suppliers/supplierService';

@Component({
	components: {},
})
export default class Login extends Vue {
	supplierService = new SupplierService();

	@Watch('$auth.isAuthenticated')
	async onAuthenticationChange(val: boolean) {
		this.$router.replace({ replace: true, name: 'SelectSupplier' });
	}

	login() {
		this.$router.replace({ replace: true, name: 'SelectSupplier' });
	}

	logout() {
		this.$auth.logout({
			returnTo: window.location.origin,
		});
	}
}
