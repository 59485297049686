import { IAddress } from './IAddress';

export class Address implements IAddress {
	public AddressLine1 = '';
	public AddressLine2 = '';
	public AddressLine3 = '';
	public AddressLine4 = '';
	public Town = '';
	public County = '';
	public Postcode = '';
	public Number = '';
	public Telephone = '';
	public Email = '';
	public Fax = '';
	public Mobile = '';
	public Website = '';

	constructor(address: any = null) {
		if (address) {
			this.InitialisePopulatedItem(address);
		}
	}

	private InitialisePopulatedItem(address: any) {
		this.AddressLine1 = address.AddressLine1;
		this.AddressLine2 = address.AddressLine2;
		this.AddressLine3 = address.AddressLine3;
		this.AddressLine4 = address.AddressLine4;

		this.Town = address.Town;
		this.County = address.County;
		this.Postcode = address.Postcode;

		this.Number = address.Number;
		this.Mobile = address.Mobile;
	}
}
