



































































































































import { Component, Vue } from 'vue-property-decorator';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import SupplierSelect from '@/components/supplierSelect/supplierSelect.vue';

// State module imports
import { HeaderModule } from '@/store/header/header';

// Component Import
import LoadingData from '@/components/datadisplay/LoadingData.vue';

@Component({
	components: {
		HeaderBar,
		SupplierSelect,
		LoadingData,
	},
})
export default class App extends Vue {
	headerModule = HeaderModule;
	async created() {
		this.headerModule.UpdateTabItems([
			{
				Name: 'Zones',
				Route: '/zones',
			},
			{
				Name: 'Dashboard',
				Route: '/dashboard',
			},
			{
				Name: 'Documents',
				Route: '/documents',
			},
			{
				Name: 'Vitals',
				Route: '/vitals',
			},
		]);
	}
}
